document.addEventListener("turbo:load", () => {
  const agreeToTerms = document.querySelector(".euro-agree-to-terms");

  if (agreeToTerms) {
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const url = '/agree_to_terms'
    const inputBox = document.querySelector(".euro-t-a-checkbox ");
    inputBox.addEventListener("click", (e) => {
      if (e.target.checked) {
        agreeToTerms.classList.add("euro-able-agree-to-terms");
        agreeToTerms.disabled = false
      } else {
        agreeToTerms.classList.remove("euro-able-agree-to-terms");
        agreeToTerms.disabled = true
      }
    })

    agreeToTerms.addEventListener("click", (e) => {
      updateUserTermsAndConditions(url, csrfToken, {agree_to_term: true})
    })


    function updateUserTermsAndConditions(url, csrfToken, params) {
      fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(params)
      })
      .then(response => {
          if (response.ok) {
            document.querySelector(".euro-terms-and-conditions-container").remove();
            window.location.href = "/euros/user_origin"
          } else {
          throw new Error('Error: ' + response.status);
          }
      });
    }
  }
})
