import { openLanguageModal, closeLanguageModal } from "./euroSignedOffFunctions";

document.addEventListener("turbo:load", () => {
  const hamburgerIcon = document.querySelector(".fa-bars");
  const euroLanguageModal = document.querySelector(".euro-language-modal");
  const euroLanguageModalBackground = document.querySelector(".euro-language-modal-background");
  const languageModalXButton = document.querySelector(".euro-close-language-modal");
  const userLanguage = document.querySelector(".user-language").dataset.userLanguage;

  if (hamburgerIcon) {
    openLanguageModal(euroLanguageModal, euroLanguageModalBackground, hamburgerIcon, userLanguage);
    closeLanguageModal(languageModalXButton, euroLanguageModal, euroLanguageModalBackground, userLanguage);
  }

})
