document.addEventListener("turbo:load", async () => {
  let userLocation = await getUserLocation()
  updateUserCountry(userLocation);

  async function getUserLocation() {
    const url = 'https://get.geojs.io/v1/ip/geo.json';
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.country;
    } catch (error) {
      console.log("an error happened fetching user country")
    }
  }

  async function updateUserCountry(userLocation) {
    const url = `/update_user_country`;
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          user: {
            country: userLocation,
          },
        }),
      });
    } catch (error) {
      console.error(error);
    }
  }
})
