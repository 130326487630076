export function openLanguageModal(euroLanguageModal, euroLanguageModalBackground, hamburgerIcon, userLanguage) {
  hamburgerIcon.addEventListener("click", (e) => {
    userLanguage === "ar" ? euroLanguageModal.style.right = 0 : euroLanguageModal.style.left = 0;
    euroLanguageModalBackground.style.visibility = "visible";
    euroLanguageModalBackground.style.opacity = 1;
  })
}

export function closeLanguageModal(euroLanguagCloseButton, euroLanguageModal, euroLanguageModalBackground, userLanguage) {
  euroLanguagCloseButton.addEventListener("click", (e) => {
    userLanguage === "ar" ? euroLanguageModal.style.right = "-300vw" : euroLanguageModal.style.left = "-300vw";
    euroLanguageModalBackground.style.opacity = 0;
    euroLanguageModalBackground.style.visibility = "hidden";
  })
  euroLanguageModalBackground.addEventListener("click", (e) => {
    userLanguage === "ar" ? euroLanguageModal.style.right = "-300vw" : euroLanguageModal.style.left = "-300vw";
    euroLanguageModalBackground.style.opacity = 0;
    euroLanguageModalBackground.style.visibility = "hidden";
  })
}
