document.addEventListener("turbo:load", () => {
  const alertElement = document.querySelector('.alert.alert-info');
  const userSignedInElement = document.querySelector('.user-signed-in');
  currentUrl = window.location.href;


  if (alertElement && alertElement.textContent.includes('Welcome! You have signed up successfully.')) {
    gtag('event', 'signup_success', {
      'event_category': 'User',
      'event_label': 'Successful Signup',
    });
  }

  if (userSignedInElement && currentUrl.includes("fantasy_football/home")) {
    gtag('event', 'signin_success', {
      'event_category': 'User',
      'event_label': 'Successful Signin'
    });
  }
});
